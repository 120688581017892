$text-color: #424242; // = MUI grey[800]
$spreadsheet-line-contrast-color: #90A8FC4C;

.reactgrid-content .rg-pane .rg-cell {
  color: $text-color;
  font-size: 14px;
}

.first-column-title {
  font-size: 18px !important;
  font-family: SoehneBreitKraftig !important;
  color: $text-color;
  font-weight: bold;
}

.first-column-cell {
  background: #e7e7e7;
  border: 1px solid #f1f1f1 !important;
  font-size: 13px;
  font-family: InstrumentSans;
  color: $text-color;
  display: flex;
  justify-content: space-between;
  max-width: 100% !important;
  width: 100%;
  text-wrap: pretty !important;
}

.rg-context-menu-option {
  font-family: InstrumentSans;
  font-size: 14px;
  color: $text-color;
}

.constrast-bg-cell {
  background: $spreadsheet-line-contrast-color;
}


.cell-ratio-bold {
  font-family: InstrumentSans;
  font-weight: bold;
}

.rg-celleditor {
  padding: 0
}

.rg-celleditor > input {
  margin-left: 3px;
  font-family: InstrumentSans;
  color: $text-color;
  font-size: 14px;
  border: none;
}