html {
    font-family: 'InstrumentSans';
    font-weight: 400;
    font-size: 16px;
}


body {
    overflow-x: hidden;
}

strong, b {
    font-family: InstrumentSans;
    font-weight: bold;
}

.logo {
    max-width: 300px;
    width: 100%;
    max-height: 120px;
}

.report-embed {
    max-width: 99.5vw;
    max-height: 99.5vh;
}

.custom-report {
    height: calc(100% - 48px) !important;
}

.report-fullscreen {
    height: 95vh;
    width: 100%;
    aspect-ratio: 16/9;
    margin-top: 4px;
    z-index: 9999999999;
}

@media screen and (min-aspect-ratio: 4/3) {
    .report-embed {
        margin: 4px 0;
    }


    .is-chatbot-sidebar-open {
        aspect-ratio: 14.5/9 !important;
    }

    .is-chatbot-sidebar-open-mobile {
        max-width: calc(95vw - 550px);
    }

}

@media screen and (max-aspect-ratio: 4/3) {
    .report-embed {
        width: 95vw;
        padding-left: 10px;
        height: 100%;
    }

    .is-chatbot-sidebar-open-mobile {
        width: calc(95vw - 550px);
    }

}

.report-hidden {
    opacity: 0;
    position: fixed;
    top: 100vh;
}


.report-hidden-mobile {
    opacity: 0;
    height: 100%;
    min-height: 1000px;
    position: fixed;
    top: 100%;
}

/*
This is a patch to fix the following issue : https://github.com/Adrian1903/drivn-powerbi-embed/issues/229
First Menu (Select) item is always :focus-visible on safari so background-color is always applied
*/
#project-menu .Mui-focusVisible {
    background-color: inherit;
}

#project-menu .Mui-focusVisible:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}


@font-face {
    font-family: 'FieldworkGeo';
    font-weight: 300;
    src: local('FieldwordkGeo'), url('../fonts/Fieldwork-geo-light.otf') format('opentype');
}

@font-face {
    font-family: 'FieldworkGeo';
    font-weight: 600;
    src: local('FieldwordkGeo'), url('../fonts/Fieldwork-geo-demibold.otf') format('opentype');
}

@font-face {
    font-family: 'SoehneBreitKraftig';
    src: local('SoehneBreitKraftig'), url('../fonts/soehne-breit-kraftig.woff2') format('woff');
}

@font-face {
    font-family: 'InstrumentSans';
    font-weight: 300;
    src: local('Instrument'), url('../fonts/InstrumentSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'InstrumentSans';
    font-weight: 600;
    src: local('Instrument'), url('../fonts/InstrumentSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Segoe UI';
    src: local('Segoe'), url('../fonts/Segoe UI.ttf') format('truetype');
}

@font-face {
    font-family: 'Segoe UI Bold';
    src: local('Segoe'), url('../fonts/Segoe UI Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Segoe UI Bold Italic';
    src: local('Segoe'), url('../fonts/Segoe UI Bold Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Segoe UI Italic';
    src: local('Segoe'), url('../fonts/Segoe UI Italic.ttf') format('truetype');
}


/* Using global classname to override TextField component styling */
.MuiFormLabel-root:not(.Mui-focused), .MuiInputLabel-root:not(.Mui-focused) {
    color: rgba(1, 1, 1, 0.5) !important;
}

@keyframes wave {
    0% {
        transform: scaleY(1);
    }
    25% {
        transform: scaleY(1.15);
    }
    50% {
        transform: scaleY(1);
    }
    75% {
        transform: scaleY(0.75);
    }
    100% {
        transform: scaleY(1);
    }
}

.wave-animation {
    display: inline-block;
    animation: wave 1s infinite ease-in-out;
}

#audio-waves-lottie-animation {
    width: 150px;
    height: 80px;
    overflow: hidden;
    display: flex;
    align-items: center;
}

#audio-waves-lottie-animation svg {
    height: 200px !important;
}
